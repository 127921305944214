import type { HandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import { createTransient } from 'utils/transient'
import type { TransientDeep } from 'utils/transient'

export type Fulfillment = {
  customer_uuid: string
  supplier_uuid?: string
  notes?: string
  pickup_facility_uuid: string
  pickup_instructions: string
  delivery_instructions: string
  pickup_number: string
  pickup_ready_date: string
  delivery_facility_uuid: string
  delivery_date: string
  handling_units: HandlingUnit[]
}

export type TransientFulfillment = TransientDeep<Fulfillment>

export function createTransientFulfillment(): TransientFulfillment {
  return createTransient({
    customer_uuid: '',
    pickup_facility_uuid: '',
    pickup_instructions: '',
    delivery_instructions: '',
    pickup_number: '',
    pickup_ready_date: '',
    delivery_facility_uuid: '',
    delivery_date: '',
    handling_units: [],
  })
}
