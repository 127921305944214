import { createContext, useContext, useState } from 'react'

import type { TransientFulfillment } from '../domain/Fulfillment'
import { createTransientFulfillment } from '../domain/Fulfillment'

export type FulfillmentFormContextValue = [
  TransientFulfillment,
  (value: TransientFulfillment) => void,
]

export const FulfillmentFormContext = createContext<
  FulfillmentFormContextValue | undefined
>(undefined)

export function useFulfillmentFormContext() {
  const context = useContext(FulfillmentFormContext)

  if (context === undefined) {
    throw new Error(
      'useFulfillmentFormContext must be used within a FulfillmentFormContext.Provider'
    )
  }

  return context
}

export function FulfillmentFormProvider({
  children,
}: {
  readonly children: React.ReactNode
}) {
  const state = useState<TransientFulfillment>(createTransientFulfillment())

  return (
    <FulfillmentFormContext.Provider value={state}>
      {children}
    </FulfillmentFormContext.Provider>
  )
}
