import type { RouteComponentProps } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import { CreateFulfillmentPage } from './create/CreateFulfillmentPage'

export function FulfillmentsRoutes({ match }: Readonly<RouteComponentProps>) {
  const { path } = match
  return (
    <Switch>
      <Route path={`${path}/`} exact component={() => <h1>List</h1>} />
      <Route path={`${path}/new`} exact component={CreateFulfillmentPage} />
      <Route path={`${path}/:id`} exact component={() => <h1>Details</h1>} />
    </Switch>
  )
}
