import { Layout } from '@loadsmart/miranda-react'

import type { PageIndices } from 'components/PageIndex'
import PageIndex from 'components/PageIndex'

import { useCreateFulfillmentForm } from './hooks/useCreateFulfillmentForm'

const indexValues: PageIndices = [
  { id: 'freight-information', label: 'Freight Information' },
]

export function CreateFulfillmentForm() {
  const [fulfillment] = useCreateFulfillmentForm()
  return (
    <Layout.Container>
      <Layout.Row columnGap="spacing-8">
        <Layout.Column offsetLg="3" sm="12" md="8" lg="6">
          <div id="freight-information">
            <h2>Freight Information</h2>
            {JSON.stringify(fulfillment, null, 4)}
          </div>
        </Layout.Column>
        <Layout.Column sm="12" md="4" lg="3">
          <PageIndex values={indexValues} />
        </Layout.Column>
      </Layout.Row>
    </Layout.Container>
  )
}
